<div class="container">
  <div class="side">
    <div class="card" id="in-progress-card">
      <div class="card-body">
        <h1 class="card-title">In-progress reports</h1>
        <div id="report-container">
          <p class="card-text" #reportText *ngFor="let report of reports">{{ report.report_code_label }}</p>
        </div>
      </div>
      <div class="card-footer">
        <a (click)="dialogVisible = true">
          <span>Start a different report</span><img alt="next page icon" src="/assets/img/next_arrow.svg" />
        </a>
      </div>
    </div>
    <div class="card" id="cash-on-hand-card">
      <div class="card-body">
        <h1 class="card-title">Cash on-hand</h1>
        <p class="card-text"></p>
      </div>
      <div class="card-footer"></div>
    </div>
  </div>
  <div class="side">
    <div class="card" id="recently-submitted-card">
      <div class="card-body">
        <h1 class="card-title">Recently submitted reports</h1>
        <p class="card-text"></p>
      </div>
      <div class="card-footer">
        <a routerLink="/reports">
          <span>Manage reports</span><img alt="next page icon" src="/assets/img/next_arrow.svg" />
        </a>
      </div>
    </div>
  </div>
</div>

<app-form-type-dialog
  [noReports]="reports.length === 0"
  (dialogClose)="dialogVisible = false"
  [dialogVisible]="dialogVisible"
></app-form-type-dialog>
