<div class="report-list-container">
  <app-table
    data-cy="report-list-component"
    [items]="items"
    [totalItems]="totalItems"
    [loading]="loading"
    [rowsPerPage]="rowsPerPage"
    [selectAll]="selectAll"
    [selectedItems]="selectedItems"
    itemName="reports"
    [sortableHeaders]="sortableHeaders"
    sortField="form_type"
    (loadTableItems)="loadTableItems($event)"
    (selectionChange)="onSelectionChange($event)"
    (selectAllChange)="onSelectAllChange($event)"
    (rowsPerPageChange)="onRowsPerPageChange($event)"
  >
    <ng-template #caption>
      <p-toolbar>
        <ng-template pTemplate="left">
          <h1 class="m-0">Manage reports</h1>
        </ng-template>
        <ng-template pTemplate="right">
          <button
            pButton
            pRipple
            label="Create report"
            data-cy="create-report"
            icon="pi pi-plus"
            class="add-button"
            (click)="showDialog()"
            [disabled]="noCashOnHand()"
          ></button>
        </ng-template>
      </p-toolbar>
    </ng-template>
    <ng-template #header>
      <th id="actions" role="columnheader">Actions</th>
    </ng-template>
    <ng-template #body let-item>
      <td>{{ item.formLabel }}</td>
      <td *ngIf="item.form_type === 'F99'; else default_report_label">
        {{ (item.formSubLabel || 'Miscellaneous Report to the FEC').toUpperCase() }}
      </td>
      <ng-template #default_report_label>
        <td>{{ item.report_code_label }}</td>
      </ng-template>
      <td>
        <ng-container *ngIf="item.coverage_from_date || item.coverage_through_date; else noCoverage"
          >{{ item.coverage_from_date | fecDate }} - {{ item.coverage_through_date | fecDate }}
        </ng-container>
        <ng-template #noCoverage> N/A </ng-template>
      </td>
      <td>{{ item.report_status }}</td>
      <td>{{ item.version_label }}</td>
      <td>{{ item.upload_submission?.created | fecDate }}</td>
      <td>
        <app-table-actions-button
          [tableActions]="rowActions"
          [actionItem]="item"
          (tableActionClick)="onRowActionClick($event.action, $event.actionItem)"
          buttonIcon="pi pi-ellipsis-v"
          buttonStyleClass="flex justify-content-center p-button-rounded p-button-primary  p-button-text mr-2"
          [buttonAriaLabel]="'edit ' + displayName(item)"
        ></app-table-actions-button>
      </td>
    </ng-template>
  </app-table>
</div>
<app-form-type-dialog
  [noReports]="totalItems === 0"
  (dialogClose)="dialogVisible = false"
  [dialogVisible]="dialogVisible"
></app-form-type-dialog>
