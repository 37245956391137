<div class="drop-down position-static">
  <p-button
    data-bs-toggle="dropdown"
    pRipple
    [icon]="buttonIcon"
    [label]="buttonLabel"
    [styleClass]="(rounded ? 'rounded-text ' : '') + buttonStyleClass"
    [ariaLabel]="buttonAriaLabel"
  ></p-button>
  <ul class="dropdown-menu">
    <li *ngFor="let action of filteredActions; let index = index">
      <button
        [disabled]="!action.isEnabled(actionItem)"
        pButton
        pRipple
        type="button"
        class="p-button-secondary p-button-text w-full text-left table-action-button"
        [id]="'button-' + index"
        (click)="tableActionClick.emit({ action, actionItem })"
      >
        {{ action.label }}
      </button>
    </li>
  </ul>
</div>
