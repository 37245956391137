<div class="report-menu">
  <div class="grid report-info">
    <div class="col-12">
      <div class="title-2">REPORT PROGRESS</div>
      <div class="report-type">{{ formLabel }}</div>
      <div class="sub-heading">{{ subLabel }}</div>
    </div>
  </div>
  <div class="grid">
    <div class="col-12" *ngIf="items$ | async">
      <p-panelMenu [model]="(items$ | async)!" [multiple]="false"></p-panelMenu>
    </div>
  </div>
</div>
