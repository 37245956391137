<ul class="navbar-nav">
  <ng-container *ngIf="headerStyle === headerStyles.DEFAULT">
    <li class="nav-item" [ngClass]="full ? 'full-width' : ''">
      <a class="nav-link" routerLink="/dashboard"><span [ngStyle]="{ 'font-size': fontSize }">Dashboard</span></a>
    </li>
    <li class="nav-item" [ngClass]="full ? 'full-width' : ''">
      <a class="nav-link" routerLink="/reports"><span [ngStyle]="{ 'font-size': fontSize }">Reports</span></a>
    </li>
    <li class="nav-item" [ngClass]="full ? 'full-width' : ''">
      <a class="nav-link disabled" routerLink="/transactions"
        ><span [ngStyle]="{ 'font-size': fontSize }">Transactions</span></a
      >
    </li>
    <li class="nav-item" [ngClass]="full ? 'full-width' : ''">
      <a class="nav-link" routerLink="/contacts"><span [ngStyle]="{ 'font-size': fontSize }">Contacts</span></a>
    </li>
    <li class="nav-item dropdown" [ngClass]="full ? 'full-width' : ''">
      <a
        class="nav-link dropdown-toggle"
        href="#"
        role="button"
        id="navbarDropdownMenuLink"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span [ngStyle]="{ 'font-size': fontSize }">Tools</span>
      </a>
      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
        <!-- Converted <a> tag to <span> for next 4 dead links so page doesn't route away -->
        <span class="dropdown-item" href="#"><span class="dead-link">Import</span></span>
        <span class="dropdown-item" href="#"><span class="dead-link">Export</span></span>
        <span class="dropdown-item" href="#"><span class="dead-link">Update cash on hand</span></span>
        <span class="dropdown-item" href="#"><span class="dead-link">FECFile Help</span></span>
      </ul>
    </li>
    <li class="nav-item" [ngClass]="full ? 'full-width' : ''">
      <a class="nav-link disabled" routerLink="#" p-disabled>
        <!--
          The greying of the bell icon is handled by an scss filter
          since the usual disabled class doesn't work on image files
        -->
        <img
          class="header-navbar-icon header-navbar-icon-disabled"
          ngSrc="assets/img/notification_bell_icon.svg"
          alt="Notifications"
          [height]="16 * ratio"
          [width]="12 * ratio"
        />
      </a>
    </li>
    <li class="nav-item" [ngClass]="full ? 'full-width' : ''">
      <a
        class="nav-link dropdown-toggle"
        href="#"
        role="button"
        id="navbarProfileDropdownMenuLink"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <img
          class="header-navbar-icon"
          ngSrc="assets/img/profile_icon.svg"
          alt="Profile"
          [height]="16 * ratio"
          [width]="8 * ratio"
        />
      </a>
      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarProfileDropdownMenuLink">
        <a class="dropdown-item" routerLink="/committee/">Account</a>
        <a class="dropdown-item" routerLink="/committee/members">Users</a>
        <!-- Converted <a> tag to <span> for next 2 dead links so page doesn't route away -->
        <a class="dropdown-item" routerLink="/login/select-committee/">Switch Committees</a>
        <span class="dropdown-item" href="#"><span class="dead-link">User Profile</span></span>
        <a class="dropdown-item" (click)="loginService.logOut()">Logout</a>
      </ul>
    </li>
  </ng-container>
  <ng-container *ngIf="headerStyle === headerStyles.LOGIN">
    <li class="nav-item" [ngClass]="full ? 'full-width' : ''">
      <a class="nav-link" [routerLink]=""><span [ngStyle]="{ 'font-size': fontSize }">Sign Up</span></a>
    </li>
    <li class="nav-item" [ngClass]="full ? 'full-width' : ''">
      <a class="nav-link" (click)="navigateToLoginDotGov()">
        <span [ngStyle]="{ 'font-size': fontSize }">Log In</span>
      </a>
    </li>
  </ng-container>
  <ng-container *ngIf="headerStyle === headerStyles.LOGOUT">
    <li class="nav-item" [ngClass]="full ? 'full-width' : ''">
      <a class="nav-link" (click)="loginService.logOut()">
        <span [ngStyle]="{ 'font-size': fontSize }">Log Out</span>
      </a>
    </li>
  </ng-container>
</ul>
