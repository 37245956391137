<div id="page-container" [ngClass]="[layoutControls.backgroundStyle]">
  <app-banner></app-banner>

  <!--  Begin Sidebar section -->
  <div *ngIf="layoutControls.showSidebar">
    <div class="inline-flex w-100">
      <div class="fec-background-gradient flex-shrink-1" id="sidebar-container">
        <app-sidebar></app-sidebar>
      </div>
      <div class="sidebar-content-container">
        <div class="header-container" *ngIf="layoutControls.showHeader">
          <app-header [headerStyle]="layoutControls.headerStyle"></app-header>
        </div>
        <div class="main-content">
          <div class="grid">
            <div *ngIf="layoutControls.showCommitteeBanner">
              <app-committee-banner></app-committee-banner>
            </div>
          </div>
          <div class="grid">
            <div class="router-outlet">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
        <div class="flex-grow-1 d-flex flex-column">
          <div class="flex-grow-1"></div>
          <app-footer [showSidebar]="true"></app-footer>
        </div>
      </div>
    </div>
  </div>
  <!--  End Sidebar section -->

  <!-- Begin No Sidebar section -->
  <div *ngIf="!layoutControls.showSidebar" id="content-offset">
    <div class="container">
      <div class="header-container" *ngIf="layoutControls.showHeader">
        <app-header [headerStyle]="layoutControls.headerStyle"></app-header>
      </div>
      <div class="main-content">
        <div class="grid" *ngIf="layoutControls.showCommitteeBanner">
          <div>
            <app-committee-banner></app-committee-banner>
          </div>
        </div>
        <div class="grid">
          <div class="col-12 router-outlet p-0">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer *ngIf="!layoutControls.showSidebar" [showUpperFooter]="layoutControls.showUpperFooter"></app-footer>
  <!-- End No Sidebar section -->
  <button
    type="button"
    *ngIf="layoutControls.showFeedbackButton"
    pButton
    pRipple
    label="Feedback"
    class="p-button-info feedback-button"
    (click)="this.feedbackOverlay.show($event)"
  ></button>
  <app-feedback-overlay></app-feedback-overlay>
</div>
