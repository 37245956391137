<ng-container *ngIf="formSubmitted || (control?.invalid && (control?.dirty || control?.touched))">
  <small class="p-error" *ngIf="control?.hasError('required')">{{ requiredErrorMessage }}</small>
  <small class="p-error" *ngIf="control?.hasError('fecIdMustBeUnique')">{{ uniqueFecIdMessage }}</small>
  <small class="p-error" *ngIf="control?.hasError('requiredTrue')">{{ requiredTrueErrorMessage }}</small>
  <small class="p-error" *ngIf="control?.hasError('minlength')">{{ minLengthErrorMessage }}</small>
  <small class="p-error" *ngIf="!control?.hasError('max') && control?.hasError('maxlength')">{{
    maxLengthErrorMessage
  }}</small>
  <small class="p-error" *ngIf="control?.hasError('min')">{{ minErrorMessage }}</small>
  <small class="p-error" *ngIf="control?.hasError('max')">{{ maxErrorMessage }}</small>
  <small class="p-error" *ngIf="control?.hasError('exclusiveMax')">{{ exclusiveMaxErrorMessage }}</small>
  <small class="p-error" *ngIf="control?.hasError('exclusiveMin')">{{ exclusiveMinErrorMessage }}</small>
  <small class="p-error" *ngIf="control?.hasError('invaliddate')">{{ invalidDateErrorMessage }}</small>
  <small class="p-error" *ngIf="control?.hasError('noDateProvided')">{{ noDateProvidedErrorMessage }}</small>
  <small class="p-error" *ngIf="control?.hasError('noCorrespondingForm3X')">{{
    noCorrespondingForm3XErrorMessage
  }}</small>
  <small
    class="p-error"
    *ngIf="!control?.hasError('maxlength') && !control?.hasError('required') && control?.hasError('email')"
    >{{ emailErrorMessage }}</small
  >
  <small class="p-error" *ngIf="control?.hasError('isAfter')">{{ isAfterMessage }}</small>
  <small
    class="p-error"
    *ngIf="
      !control?.hasError('required') &&
      !control?.hasError('minlength') &&
      !control?.hasError('maxlength') &&
      !control?.hasError('min') &&
      !control?.hasError('max') &&
      !control?.hasError('exclusiveMax') &&
      !control?.hasError('exclusiveMin') &&
      !control?.hasError('invaliddate') &&
      !control?.hasError('isAfter') &&
      control?.hasError('pattern')
    "
    >{{ patternErrorMessage }}</small
  >
</ng-container>
